<template>
    <admin-template>
        <template slot="drawers">
            <product-category-create-drawer
                v-model="showCreateDrawer"
                :category="selectedCategory"
            />

            <product-category-delete-drawer v-model="showDeleteDrawer" :item="selectedCategory" />
        </template>

        <template slot="content">
            <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                <v-toolbar-title>
                    <span class="headline">Categorías de Productos</span>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-toolbar-title>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                x-small
                                color="primary"
                                class="elevation-0"
                                @click.stop="onNewSelected"
                                v-on="on"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Nueva Categoría</span>
                    </v-tooltip>
                </v-toolbar-title>
            </v-toolbar>

            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-text>
                            <v-data-table
                                hide-default-footer
                                hide-default-header
                                :items="categories"
                                :loading="isLoading"
                                :headers="headers"
                                :items-per-page="-1"
                                :sort-by="['date']"
                                :sort-desc="true"
                                must-sort
                            >
                                <template
                                    v-slot:item.name="{ item }"
                                >{{ item.name | capitalize }}</template>

                                <template v-slot:item.actions="{ item }">
                                    <v-btn icon @click.stop="onEditSelected(item)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>

                                    <v-btn icon @click.stop="onDeleteSelected(item)">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </admin-template>
</template>

<script>
import AdminTemplate from '../AdminTemplate.vue';
import ProductCategoryCreateDrawer from '@/pages/Admin/ProductCategory/ProductCategoryCreateDrawer';
import ProductCategoryDeleteDrawer from '@/pages/Admin/ProductCategory/ProductCategoryDeleteDrawer';

export default {
    components: {
        AdminTemplate,
        ProductCategoryCreateDrawer,
        ProductCategoryDeleteDrawer
    },

    data() {
        return {
            showCreateDrawer: false,
            showDeleteDrawer: false,
            selectedCategory: null,

            headers: [
                { text: 'Nombre', value: 'name' },
                { text: 'Acciones', value: 'actions', align: 'end' }
            ]
        };
    },

    computed: {
        isLoading() {
            return this.$store.getters['productCategory/isLoading']();
        },

        categories() {
            return this.$store.getters['productCategory/getAll']();
        }
    },

    watch: {
        showDeleteDrawer(val) {
            if (val) {
                this.showCreateDrawer = false;
            }
        },

        showCreateDrawer(val) {
            if (val) {
                this.showEditDrawer = false;
            }
        }
    },

    mounted() {
        this.$store.dispatch('productCategory/fetchAll', {});
    },

    methods: {
        onNewSelected() {
            this.showCreateDrawer = true;
            this.selectedCategory = null;
        },

        onEditSelected(item) {
            this.showCreateDrawer = true;
            this.selectedCategory = item;
        },

        onDeleteSelected(category) {
            this.showDeleteDrawer = true;
            this.selectedCategory = category;
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
