<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="active = false">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>

                        <h1 v-if="!this.category" class="headline" style="white-space: nowrap">Agregar Categoría</h1>
                        <h1 v-else class="headline" style="white-space: nowrap">Editar Categoría</h1>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <validation-observer ref="form">
                        <v-form>
                            <validation-provider name="name" v-slot="{ errors }" rules="required">
                                <v-text-field
                                    v-model="data.name"
                                    label="Nombre"
                                    :error-messages="errors"
                                />
                            </validation-provider>

                            <v-checkbox
                                v-model="data.visible"
                                label="Visible"
                                :error-messages="errors"
                            />
                        </v-form>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

               <v-btn color="primary"
                    @click="onSaveSelected"
                    :disabled="saving"
                    :loading="saving"
                    dark block
                >
                    Agregar
                </v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },

        props: {
            category: Object,
            value: Boolean
        },

        data() {
            return {
                active: false,
                saving: false,

                data: {
                    name: null,
                    visible: false
                }
            };
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            },

            category(val) {
                if(val) {
                    this.data = {
                        name: val.name,
                        visible: val.display
                    };
                }
                else {
                    this.data = {
                        name: '',
                        visible: false
                    };
                }
            }
        },

        methods: {
            async onSaveSelected() {
                let result = await this.$refs.form.validate();
                if(!result) {
                    return;
                }

                console.log(this.data);

                this.saving = true;

                try {
                    if(this.category != null) {
                        await this.$store.dispatch('productCategory/update', {
                            category: this.category,
                            data: this.data
                        });
                    }
                    else {
                        await this.$store.dispatch('productCategory/create', {
                            name: this.data.name,
                            visible: this.data.visible
                        });
                    }

                    this.data = {
                        name: null,
                        visible: false
                    };
                    this.$refs.form.reset();

                    this.active = false;
                }
                catch (err) {
                    console.error(err);
                }
                finally {
                    this.saving = false;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
